import { ImageProps } from 'next/image'

import Icon from '@Components/icon'
import Image from '@Components/Image'

type IconItemProps = ImageProps & {
  color?: string
}

const IconItem = ({
  height,
  width,
  color,
  src,
  alt,
  ...rest
}: IconItemProps) => {
  const iconAttr = (svg: {
    setAttribute: (arg0: string, arg1: string) => void
  }) => {
    svg.setAttribute('style', `${color ? `fill: ${color}` : ''}`)
    svg.setAttribute('height', `${height}`)
    svg.setAttribute('width', `${width}`)
    alt && svg.setAttribute('alt', alt)
  }

  return (src as string).includes('.svg') ? (
    <Icon
      className="relative mb-[10px] mr-[10px] box-border inline-block"
      src={src as string}
      width={width}
      height={height}
      beforeInjection={iconAttr}
    />
  ) : (
    <div className="relative mb-[10px] mr-[10px] box-border inline-block pb-0 last:mr-0">
      <Image
        src={src}
        width={width}
        height={height}
        alt={alt ?? ''}
        {...rest}
      />
    </div>
  )
}

export default IconItem
