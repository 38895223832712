import { ReactNode } from 'react'

type IconListProps = {
  title: string
  children?: ReactNode
}

const IconList = ({ children, title }: IconListProps) => (
  <div className="pb-4 last:pb-0">
    <h3 className="pb-5 font-uol-bold text-base">{title}</h3>
    <div className="flex flex-wrap items-baseline lg:max-w-[250px]">
      {children}
    </div>
  </div>
)

export default IconList
