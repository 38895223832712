import { z } from 'zod'

import { formatPhoneNumber } from '@Shared/helpers/string-operator'

const minPhoneLength = 10
const maxPhoneLength = 11

export const BusinessFormSchema = z
  .object({
    companyName: z.string().nonempty({ message: 'Informe o nome da empresa.' }),
    eventName: z.string().nonempty({ message: 'Informe o nome do evento.' }),
    email: z
      .string()
      .email({ message: 'Informe um e-mail válido.' })
      .optional()
      .or(z.literal('')),
    phone: z
      .string()
      .refine(
        (phone) =>
          formatPhoneNumber(phone).length >= minPhoneLength &&
          formatPhoneNumber(phone).length <= maxPhoneLength,
        {
          message: 'Informe um telefone válido.'
        }
      )
      .optional()
      .or(z.literal(''))
  })
  .refine((schema) => schema.email || schema.phone, {
    path: ['phone'],
    message: 'Informe um e-mail ou telefone para contato.'
  })

export type BusinessForm = z.infer<typeof BusinessFormSchema>
