'use client'

import Dialog from 'src/ui/dialog/dialog'

import { eventCategory } from '@Shared/helpers/tracking'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import BusinessContactModal from '@Components/modal-components/business-contact-modal'

const BusinessContact = () => {
  const pageName = usePageName()

  const handleTracking = () => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: 'Click on Footer Contact',
      event_label: '',
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <div
      className="mx-2 my-4 lg:mx-4 lg:my-10 xl:ml-16"
      data-testid="business-contact"
    >
      <div className="mx-auto max-w-[272px] rounded-2xl bg-ing-neutral-600 px-[30px] py-4 text-center lg:max-w-[208px] lg:p-4">
        <h3 className="mb-2 text-base">Venda ingressos online</h3>
        <p className="mb-4 text-center text-xs text-[#ccc]">
          Buscando parcerias ou soluções para o seu evento?
        </p>
        <Dialog.Root>
          <Dialog.Trigger
            className="bg-ing-gradient-yellow h-[40px] w-[176px] cursor-pointer rounded-[10px] border-none font-uol-bold text-base text-black hover:opacity-90"
            onClick={handleTracking}
          >
            Venda conosco
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay />
            <BusinessContactModal />
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </div>
  )
}

export default BusinessContact
