import { policiesLinks } from '@Shared/constants/footer'
import { eventCategory } from '@Shared/helpers/tracking'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

const Policies = () => {
  const pageName = usePageName()

  const policiesTracking = (label: string) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on Footer Policies`,
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <ul className="columns-2 gap-5 lg:mt-4 lg:columns-1">
      {policiesLinks.map((item) => (
        <li
          className="mb-2 text-[11px] lg:text-sm lg:leading-4"
          key={item.label}
          onClick={() => {
            policiesTracking(item.label)
          }}
        >
          <Link
            className="flex items-center text-ing-blue-400 no-underline"
            href={item.url}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
export default Policies
