import { zodResolver } from '@hookform/resolvers/zod'
import {
  ComponentPropsWithoutRef,
  forwardRef,
  InputHTMLAttributes
} from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import InputMask from 'react-input-mask'
import Button from 'src/ui/button'
import Dialog from 'src/ui/dialog'

import { formatPhoneNumber, phoneMask } from '@Shared/helpers/string-operator'

import {
  BusinessForm,
  BusinessFormSchema
} from '@Schemas/application/form/business-contact'

import Icon from '@Components/icon'
import Link from '@Components/Link'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string
}

type BusinessContactModalProps = ComponentPropsWithoutRef<'div'>

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <>
      <input
        ref={ref}
        {...props}
        className="box-border h-[34px] w-full rounded border border-l-4 border-solid border-ing-blue-400 pl-3 text-black placeholder-[#999] outline-none placeholder:text-sm"
      />

      {props.error && (
        <div className="flex items-center gap-2">
          <Icon src="/images/alert.svg" />
          <p className="text-sm text-[#ffa928]">{props.error}</p>
        </div>
      )}
    </>
  )
})
Input.displayName = 'Input'

const BusinessContactModal = forwardRef<
  HTMLDivElement,
  BusinessContactModalProps
>((props, ref) => {
  const methods = useForm<BusinessForm>({
    resolver: zodResolver(BusinessFormSchema),
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      eventName: '',
      email: '',
      phone: ''
    }
  })

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors }
  } = methods
  const phone = formatPhoneNumber(getValues('phone') || '')

  const onSubmit = ({ companyName, eventName, email, phone }: BusinessForm) => {
    const subject = `Contato para parceria - ${companyName}`
    const body = `Nome da empresa: ${companyName} | Nome do evento: ${eventName} ${email} | E-mail para contato: ${email} | ${phone} | Telefone para contato: ${phone}`

    const mailtoLink = `mailto:vendaconosco@ingresso.com?subject=${subject}&body=${body}`
    window.location.href = mailtoLink
  }

  return (
    <Dialog.Content
      className="fixed left-0 top-0 h-full max-h-full w-full translate-x-0 translate-y-0 rounded-none bg-ing-neutral-600 p-4 sm:left-1/2 sm:top-1/2 sm:h-auto sm:max-h-[85vh] sm:w-[380px] sm:-translate-x-1/2 sm:-translate-y-1/2 sm:rounded-xl lg:w-[380px]"
      data-testid="business-contact-modal"
      ref={ref}
      {...props}
    >
      <Dialog.Header className="items-center bg-transparent sm:h-20 sm:p-3 lg:h-auto">
        <div className="flex justify-center">
          <Icon src="/images/ticket-solid.svg" />
        </div>
        <Dialog.Close>
          <Icon
            src="/images/close.svg"
            className="absolute right-2 top-2 cursor-pointer [&_path]:fill-ing-neutral [&_svg]:h-7 [&_svg]:w-7"
          />
        </Dialog.Close>
      </Dialog.Header>
      <div className="w-full px-4">
        <Dialog.Title className="mb-2 text-xl">
          Venda ingressos online
        </Dialog.Title>
        <p className="mb-4 text-sm text-[#ccc]">
          Se você está buscando parcerias ou soluções para o seu evento,
          preencha os dados abaixo.
        </p>
        <FormProvider {...methods}>
          <form
            data-testid="business-form"
            className="flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              type="text"
              {...register('companyName')}
              placeholder="Nome da empresa"
              error={errors.companyName?.message}
            />
            <Input
              type="text"
              {...register('eventName')}
              placeholder="Nome do evento"
              error={errors.eventName?.message}
            />
            <Input
              type="email"
              {...register('email')}
              placeholder="E-mail para contato"
              error={errors.email?.message}
            />
            <Controller
              name={'phone'}
              control={control}
              render={({ field }) => (
                <InputMask mask={phoneMask(phone)} {...field}>
                  {(inputProps) => (
                    <Input
                      type="tel"
                      placeholder="Telefone para contato"
                      error={errors.phone?.message}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              )}
            />
            <Button type="submit" className="my-6 w-fit">
              Enviar
            </Button>
          </form>
        </FormProvider>
        <div className="flex flex-col gap-4 border-t border-solid border-t-[#333] pt-6">
          Informações de contato
          <div className="flex gap-2">
            <Icon
              src="/images/email.svg"
              className="fill-white [&_svg]:h-8 [&_svg]:w-8"
            />
            <div className="flex flex-col items-start justify-center">
              <strong className="text-sm">E-mail</strong>
              <Link
                href="mailto:vendaconosco@ingresso.com"
                className="text-xs text-[#ccc]"
              >
                vendaconosco@ingresso.com
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Dialog.Content>
  )
})
BusinessContactModal.displayName = 'BusinessContactModal'

export default BusinessContactModal
