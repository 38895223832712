import { paymentList } from '@Shared/constants/footer'

import Image from '@Components/Image'
import IconList from '@Components/page-footer/icon-list'
import IconItem from '@Components/page-footer/icon-list/icon-item'

const Payment = () => (
  <>
    <div className="px-0 py-4 lg:px-4 lg:py-10 lg:text-sm">
      <strong>Formas de Pagamento</strong>
      <div className="pt-2 text-[10px] lg:flex lg:justify-between lg:pt-4 lg:text-sm">
        {paymentList.map((type) => (
          <IconList key={type.label} title={type.label}>
            {type.items.map((item) => (
              <IconItem
                key={item.title}
                src={
                  item.icon ? item.icon : `/images/payment/${item.title}.svg`
                }
                height={30}
                width={40}
                alt={`Logo da ${item.title}`}
              />
            ))}
          </IconList>
        ))}
      </div>
    </div>
    <div className="flex flex-wrap gap-6 border-t border-solid border-ing-neutral-600 px-0 py-4 lg:gap-16 lg:px-4 lg:py-10">
      <IconList title="Selo do Consumidor">
        <Image
          className="h-full w-full max-w-[125px] lg:h-auto lg:w-auto lg:max-w-[185px]"
          src="/images/consumidor-gov-br.png"
          alt="Imagem Participo do consumidor.gov.br"
          height={45}
          width={185}
        />
      </IconList>

      <IconList title="Campeã em Atendimento">
        <Image
          className="h-full w-full max-w-[125px] lg:h-auto lg:w-auto lg:max-w-[185px]"
          src="https://ingresso-a.akamaihd.net/sitenovo-2017/comum/img/ingresso-awards-2019.png"
          alt="11 vezes vencedora do Prêmio Reclame Aqui"
          height={85}
          width={185}
        />
      </IconList>

      <IconList title="O melhor app de ingresso">
        <Image
          className="h-full w-full max-w-[125px] lg:h-auto lg:w-auto lg:max-w-[185px]"
          src="https://ingresso-a.akamaihd.net/sitenovo-2017/comum/img/ingresso-best-app.png"
          alt="O melhor de São Paulo Cinema - Guia Folha 2023 - Melhor APP de Ingresso"
          height={56}
          width={121}
        />
      </IconList>
    </div>
  </>
)

export default Payment
