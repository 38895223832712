const iconUrl = `/images/payment`

export const institutionalLinks = [
  {
    title: 'Quem Somos',
    url: 'https://www.ingresso.com/institucional',
    label: 'Who we are?'
  },
  {
    title: 'Assessoria de Imprensa',
    url: '/institucional/assessoria-imprensa',
    label: 'Press Office'
  },
  {
    title: 'Vale-Presente Corporativo',
    url: 'https://b2b.ingresso.com/',
    label: 'Corporate Gift Voucher'
  },
  {
    title: 'Ingresso.com atende',
    url: '/institucional/ingresso-atende',
    label: 'Ingresso Support'
  },
  {
    title: 'movieID.com',
    url: 'https://www.movieid.com/',
    label: 'Ingresso MovieID'
  },
  {
    title: 'Faça parte do time',
    url: 'https://uol.gupy.io/',
    label: 'Ingresso Join Us'
  }
]

export const policiesLinks = [
  {
    title: 'Privacidade e Segurança',
    url: 'https://sac.ingresso.com/portal/pt-br/kb/articles/pol%C3%ADtica-de-privacidade',
    label: 'Privacy and Security'
  },
  {
    title: 'Meia-entrada',
    url: 'https://sac.ingresso.com/portal/pt-br/kb/atendimento-ingresso-com/pol%C3%ADticas-do-site/pol%C3%ADticas-de-meia-entrada',
    label: 'Half Entrance'
  },
  {
    title: 'Trocas e Cancelamentos',
    url: 'https://sac.ingresso.com/portal/pt-br/kb/articles/leia-nossa-pol%C3%ADtica-de-trocas-e-cancelamentos-de-ingressos',
    label: 'Exchanges and Cancellations'
  },
  {
    title: 'Leis Estaduais e Municipais',
    url: 'https://sac.ingresso.com/portal/pt-br/kb/atendimento-ingresso-com/pol%C3%ADticas-do-site/leis-estaduais-e-municipais',
    label: 'State and Municipal Laws'
  },
  {
    title: 'Termos de Uso',
    url: 'https://sac.ingresso.com/portal/pt-br/kb/articles/termos-de-uso',
    label: 'Terms'
  }
]

export const socialMediaList = [
  {
    label: 'Facebook',
    url: process.env.INGRESSO_FACEBOOK
  },
  {
    label: 'Youtube',
    url: process.env.INGRESSO_YOUTUBE
  },
  {
    label: 'Instagram',
    url: process.env.INGRESSO_INSTAGRAM
  },
  {
    label: 'LinkedIn',
    url: process.env.INGRESSO_LINKEDIN
  }
]

export const helpLinks = [
  {
    title: 'Atendimento',
    url: 'https://atendimento.ingresso.com/portal/pt-br/kb/atendimento-ingresso-com',
    label: 'Support Link'
  },
  {
    title: 'Sites ou e-mails falsos',
    url: '/politicas/alerta-emails-sites-falsos',
    label: 'Sites or E-mails Fakes'
  },
  {
    title: 'Procon-RJ',
    url: 'http://www.procon.rj.gov.br/',
    label: 'Procon-RJ'
  }
]

export const cancelOrder = {
  title: 'Cancelar Pedido',
  url: 'https://atendimento.ingresso.com/portal/pt-br/kb/articles/como-cancelar-uma-compra-na-ingresso-com#Para_clientes_cadastrados',
  label: 'Cancel Order'
}

export const paymentList = [
  {
    label: 'Crédito',
    items: [
      { title: 'visa' },
      { title: 'mastercard' },
      { title: 'elo' },
      { title: 'aura' },
      { title: 'american-express' },
      { title: 'hipercard' },
      { title: 'pagbank' }
    ]
  },
  {
    label: 'Débito',
    items: [
      { title: 'c6' },
      { title: 'nubank' },
      { title: 'itau' },
      { title: 'bradesco' },
      { title: 'next' },
      { title: 'banco-santander' },
      { title: 'banco-do-brasil' },
      { title: 'inter' },
      { title: 'caixa-economica' },
      { title: 'pagbank' }
    ]
  },
  {
    label: 'Outros pagamentos',
    items: [
      { title: 'pay-pal' },
      { title: 'apple-pay' },
      { title: 'google-pay' },
      { title: 'pix', icon: `${iconUrl}/pix.png` }
    ]
  },
  {
    label: 'Troque seus pontos',
    items: [
      { title: 'easylive', icon: `${iconUrl}/easylive.png` },
      { title: 'livelo', icon: `${iconUrl}/livelo.png` },
      { title: 'tudo-azul', icon: `${iconUrl}/tudo-azul.png` },
      { title: 'dotz', icon: `${iconUrl}/dotz.png` }
    ]
  }
]
