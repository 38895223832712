'use client'

import { eventCategory } from '@Shared/helpers/tracking'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Image from '@Components/Image'
import Link from '@Components/Link'

import BusinessContact from './business-contact'
import LinkArea from './link-area'
import Payment from './payment-methods'
const currentYear = new Date().getFullYear()

const Footer = () => {
  const pageName = usePageName()

  const footerTracking = (label: string) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: 'Click on Footer',
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <footer className="mt-9 flex flex-col bg-ing-neutral-700">
      <div className="relative flex h-[60px] bg-[url('https://ingresso-a.akamaihd.net/catalog/img/highlight-footer.jpg')] bg-cover bg-no-repeat before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:bg-[#191919] before:opacity-80 before:content-[''] lg:h-[120px]">
        <div className="relative flex items-center pl-4 lg:mx-auto lg:my-0 lg:w-full lg:max-w-screen-xl">
          <strong className="mr-2 text-sm font-normal lg:mr-[104px] lg:text-[34px]">
            Baixe nosso aplicativo
          </strong>
          <div className="flex lg:gap-10">
            <div
              className="relative h-[30px] w-[86px] lg:h-14 lg:w-[154px]"
              onClick={() => footerTracking('App Store')}
            >
              <Link
                className="no-underline hover:opacity-80"
                href="https://itunes.apple.com/br/app/ingresso-com/id1165054492?mt=8"
              >
                <Image
                  src="/images/app-store.png"
                  alt="Imagem app store"
                  fill
                />
              </Link>
            </div>
            <div
              className="relative h-7 w-[86px] lg:h-14 lg:w-[172px]"
              onClick={() => footerTracking('Google Play')}
            >
              <Link
                className="no-underline hover:opacity-80"
                href="https://play.google.com/store/apps/details?id=com.ingresso.cinemas&hl=pt_BR"
              >
                <Image
                  src="/images/google-play.png"
                  alt="Imagem google play"
                  fill
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl px-4 py-0 lg:mx-auto lg:my-0 lg:w-full lg:p-0">
        <div className="border-b border-solid border-b-ing-neutral-600 lg:flex">
          <LinkArea />
          <BusinessContact />
        </div>
        <Payment />
        <div className="border-t border-solid border-t-ing-neutral-600 px-0 pb-[60px] pt-4 text-center text-[10px] text-[#999] lg:px-0 lg:py-10">
          <p>
            Ingresso.com Ltda / CNPJ: 008606400001-71 Endereço: Rua da Quitanda,
            86 - 3º andar - Centro - RJ - 20091-005
          </p>
          <Link
            href="https://atendimento.ingresso.com/portal/pt-br/kb/atendimento-ingresso-com"
            onClick={() => footerTracking('Support Smaller')}
            className="text-ing-blue-400 no-underline hover:opacity-80"
          >
            Atendimento ao cliente
          </Link>{' '}
          © {currentYear} - Copyright Ingresso.com - todos os direitos
          reservados
        </div>
      </div>
    </footer>
  )
}

export default Footer
