import { ComponentProps } from 'react'

type SupportBoxProps = ComponentProps<'div'>

const SupportBox = ({ children }: SupportBoxProps) => {
  return (
    <>
      <div className="mb-4 border-b border-solid border-b-ing-neutral-500">
        {children}
      </div>
      <section className="box-border text-xs">
        <strong className="text-sm">Quer cancelar um pedido?</strong>
        <div>
          <p className="my-4">
            Se voce comprou logado como cliente Ingresso.com, voce pode cancelar
            seu pedido sem a necessdade de contactar um atendente.
          </p>
          <p className="mb-2 block text-[#25d366]">Para cancelar a compra:</p>
          <ol className="mx-3 my-4 list-decimal [&_li]:mb-2 [&_li]:marker:text-[#25d366]">
            <li>Você será redirecionado para a tela dos seus pedidos.</li>
            <li>Clique no botão &quot;Ver detalhes&quot;.</li>
            <li>Em seguida clique em &quot;cancelar pedido&quot;.</li>
            <li>Siga o passo a passo para cancelar</li>
          </ol>
        </div>
        <div className="flex flex-col gap-4">
          <a
            href={`${process.env.CONTROL_PANEL_URL}/meus-pedidos`}
            target="_blank"
            className="box-border flex min-h-[45px] w-full items-center justify-center rounded-[3px] bg-ing-blue-400 py-2 text-center font-uol-bold text-xs uppercase text-[#252525] no-underline"
            rel="noreferrer"
          >
            SIM, QUERO CANCELAR UM PEDIDO
          </a>
          <a
            href="https://atendimento.ingresso.com/"
            target="_blank"
            className="flex min-h-[45px] w-full items-center justify-center bg-transparent font-uol-bold text-ing-blue-400 no-underline"
            rel="noreferrer"
          >
            NÃO, QUERO OUTRO TIPO DE ATENDIMENTO
          </a>
        </div>
      </section>
    </>
  )
}

export default SupportBox
